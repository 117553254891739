export const VOICE_LANGUAGE_OPTIONS = [
  { label: 'English', value: 'en' },
  { label: 'Mandarin', value: 'zh' },
  { label: 'Spanish', value: 'es' },
  { label: 'Hindi', value: 'hi' },
  { label: 'French', value: 'fr' },
  { label: 'French (Canada)', value: 'fr-CA' },
  { label: 'Russian', value: 'ru' },
  { label: 'German', value: 'de' },
  { label: 'Portuguese', value: 'pt' },
];

export enum TTS_SPEED {
  SLOWEST = 1,
  SLOW = 2,
  NORMAL = 3,
  FAST = 4,
  FASTEST = 5,
}

export const MANDARIN = 'mandarin';
