import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRTVIClientTransportState } from '@pipecat-ai/client-react';
import { IconPhone } from '@tabler/icons-react';

import {
  FilterButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { useGetThemeBasedOnMode } from '../utils';
import { useStateParams } from 'src/hooks/hooks';
import { useGetBoughtNumberOptions } from 'src/pages/solve-config/voice/utils';
import {
  getBoughtPhoneNumbers,
  selectBoughtNumbers,
} from 'src/slices/solve-config/solveConfigSlice';
import {
  selectPreviewPhoneNumber,
  setVoicePreviewPhoneNumber,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';
import { genericSerializeAndDeserialize } from 'src/utils/discover/helpers';

export function PhoneNumberSelector() {
  const dispatch = useAppDispatch();
  const theme = useGetThemeBasedOnMode();
  const transportState = useRTVIClientTransportState();
  const boughtNumberOptions = useGetBoughtNumberOptions();
  const previewPhoneNumber = useSelector(selectPreviewPhoneNumber);
  const isIdle = ['error', 'disconnected', 'disconnecting'].includes(
    transportState,
  );
  const boughtNumbers = useSelector(selectBoughtNumbers);

  const [number, setNumber] = useStateParams<string>({
    deserialize: (param: string) => param,
    initialState: '',
    paramsName: 'number',
    replace: true,
    serialize: genericSerializeAndDeserialize,
  });

  useEffect(() => {
    dispatch(getBoughtPhoneNumbers());
  }, [dispatch]);

  useEffect(() => {
    if (number && number !== previewPhoneNumber) {
      dispatch(setVoicePreviewPhoneNumber(number));
    }
  }, [dispatch, previewPhoneNumber, number, setNumber]);

  useEffect(() => {
    if (boughtNumbers.length > 0 && !previewPhoneNumber && !number) {
      dispatch(setVoicePreviewPhoneNumber(boughtNumbers[0].phone_number));
    }
  }, [boughtNumbers, dispatch, number, previewPhoneNumber]);

  return (
    <Tooltip
      placement='top'
      tooltipContent={
        !isIdle
          ? 'Cannot update phone number due to an ongoing conversation'
          : ''
      }
    >
      <FilterButton
        aria-label='Preview with phone number'
        disabled={!isIdle}
        initialValue=''
        onChange={e => {
          setNumber(e);
        }}
        options={boughtNumberOptions}
        startAdornment={
          <IconPhone color={theme.palette.colors.grey[500]} size={20} />
        }
        value={previewPhoneNumber || ''}
      />
    </Tooltip>
  );
}
