import { useState } from 'react';
import { Box, Menu, useTheme } from '@mui/material';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { IconDotsVertical } from '@tabler/icons-react';

import {
  Button,
  Dialog,
  IconButton,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { StyledMenuItem } from 'src/pages/workflow-builder/DropdownMenu';
import { AiQaConfig } from 'src/services/insights/types';

interface AiQaConfigurationCardProps {
  data: AiQaConfig;
  isLoadingDelete: boolean;
  isLoadingUpdate: boolean;
  onDeleteOnClick: () => void;
  onEditOnClick: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateConfigMutation: MutationTrigger<any>;
}

const AiQaConfigurationCard = ({
  data,
  isLoadingDelete,
  isLoadingUpdate,
  onDeleteOnClick,
  onEditOnClick,
  updateConfigMutation,
}: AiQaConfigurationCardProps) => {
  const { palette } = useTheme();

  // Confirmation dialog
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);

  const handleOnUpdateClick = async () => {
    try {
      await updateConfigMutation({
        ...data,
        is_active: !data.is_active,
      }).unwrap();
    } catch (err) {
      console.error('Failed to update configuration', err);
    }
  };

  // Menu setup - Edit/Delete
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isMenuOpen = Boolean(anchorEl);

  const lastUpdatedString = `Last updated on ${new Date(
    data.last_updated_at,
  ).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })} by ${data.last_updated_by}`;

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: palette.colors.white,
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 3,
          }}
        >
          <Box display='flex' flexDirection='column' gap={2} maxWidth='200px'>
            <Typography variant='font20'>{data.model_name}</Typography>
            <Box>{data.is_active}</Box>
            <Toggle
              checked={data.is_active}
              disabled={isLoadingUpdate}
              onChange={handleOnUpdateClick}
            />
            <Typography color={palette.grey[600]} variant='font12Medium'>
              {lastUpdatedString}
            </Typography>
          </Box>
          <Box borderRight={`1px solid ${palette.colors.slate[300]}`} />
          <Box display='flex' flexDirection='column' gap='10px'>
            <Typography variant='font14Bold'>Definition</Typography>
            <p
              style={{
                display: '-webkit-box',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                letterSpacing: '-0.1px',
                lineHeight: '20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,
                whiteSpace: 'pre-wrap',
              }}
            >
              {data.model_definition}
            </p>
          </Box>
        </Box>
        <IconButton aria-label='menu' onClick={handleClick} variant='ghost'>
          <IconDotsVertical size={20} />
        </IconButton>
      </Box>
      {/* Menu */}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={(e: React.MouseEvent) => {
          e.stopPropagation();
          handleClose();
        }}
        open={isMenuOpen}
        sx={{
          '& .MuiPaper-root': {
            border: `1px solid ${palette.colors.grey[200]}`,
            borderRadius: '4px',
            boxShadow:
              '0px 0px 1px rgba(0, 12, 32, 0.04), 2px 5px 8px rgba(3, 17, 38, 0.107135)',
            padding: '4px 0',
            width: '235px',
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <StyledMenuItem
          aria-label='Edit'
          disableRipple
          onClick={e => {
            e.stopPropagation();
            onEditOnClick();
            handleClose();
          }}
        >
          <Typography variant='font14'>Edit</Typography>
        </StyledMenuItem>
        <StyledMenuItem
          aria-label='Edit'
          disableRipple
          onClick={e => {
            e.stopPropagation();
            setIsConfirmationDialogOpen(true);
            handleClose();
          }}
        >
          <Typography color={palette.colors.red[500]} variant='font14'>
            Delete
          </Typography>
        </StyledMenuItem>
      </Menu>
      {/* Confirmation dialog */}
      <Dialog
        footer={
          <>
            <Button
              onClick={() => setIsConfirmationDialogOpen(false)}
              variant='ghost'
            >
              Cancel
            </Button>
            <Button
              disabled={isLoadingDelete}
              isLoading={isLoadingDelete}
              onClick={() => {
                onDeleteOnClick();
                setIsConfirmationDialogOpen(false);
              }}
              variant='secondary'
            >
              Continue
            </Button>
          </>
        }
        onClose={() => setIsConfirmationDialogOpen(false)}
        open={isConfirmationDialogOpen}
        title='Delete AI QA metrics?'
      >
        <Box width='345px'>
          Are you sure you want to delete this metrics? This action cannot be
          undone.
        </Box>
      </Dialog>
    </>
  );
};

export default AiQaConfigurationCard;
