import { ComponentProps, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  Colors,
  LoadingIndicator,
  Tabs,
  Toggle,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetSelectedBrand } from '../hooks';
import { usePublishedTimestamp } from '../hooks/usePublishedTimestamp';
import { Channel } from '../types';
import { MessageComponent } from './MessageComponent';
import capitalize from 'lodash/fp/capitalize';
import ConfirmModal from 'src/components/email-builder/components/ConfirmModal';
import {
  useIsFeatureFlagEnabled,
  useSolveConfigTrackingEventAction,
} from 'src/hooks/hooks';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import {
  useCreateBrandMutation,
  useDeleteBrandMutation,
  useGetBrandsQuery,
  useGetWhitelistedDomainsQuery,
  useUpdateBrandMutation,
  useUpdateBrandTagsMutation,
  useUpdateWhitelistedDomainsMutation,
} from 'src/services/brand/brandApi';
import { useGetPaidPlanDetailsQuery } from 'src/services/dashboard-api';
import {
  ALLOW_LIST_EMPTY,
  PUBLISHED_RECENTLY,
} from 'src/slices/solve-config/constants';
import {
  activateWidget,
  deactivateWidget,
  publishConfiguration,
  publishPlaybookConfiguration,
  publishVoiceConfiguration,
  selectCanPublish,
  selectCanPublishVoiceConfig,
  selectCanUpdatePublish,
  selectIsActive,
  selectIsPlaybookConfigModified,
  selectIsPlaybookValidationFailing,
  selectIsSaving,
  selectIsValidationFailing,
  selectIsVoiceConfigModified,
  selectLiveVersionExists,
  selectLiveVersionExistsPlaybook,
  selectSelectedPhoneNumber,
  selectValidationErrors,
  setLiveVersionIsActive,
  showWarningModal,
} from 'src/slices/solve-config/solveConfigSlice';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { RootState } from 'src/store/rootReducer';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';

const useIsGlobalMutationsSaving = () => {
  const [, { isLoading: isLoadingCreate }] = useCreateBrandMutation({
    fixedCacheKey: 'createBrand',
  });
  const [, { isLoading: isLoadingUpdate }] = useUpdateBrandMutation({
    fixedCacheKey: 'updateBrand',
  });
  const [, { isLoading: isLoadingDelete }] = useDeleteBrandMutation({
    fixedCacheKey: 'deleteBrand',
  });
  const [, { isLoading: isLoadingUpdateTags }] = useUpdateBrandTagsMutation({
    fixedCacheKey: 'updateBrandTags',
  });
  const [, { isLoading: isLoadingUpdateWhitelistedDomains }] =
    useUpdateWhitelistedDomainsMutation({
      fixedCacheKey: 'updateWhitelistedDomains',
    });
  const isLoading =
    isLoadingCreate ||
    isLoadingUpdate ||
    isLoadingDelete ||
    isLoadingUpdateTags ||
    isLoadingUpdateWhitelistedDomains;
  return isLoading;
};

const useMultibrandValidationErrors = () => {
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const {
    data: whitelistedDomainsData,
    isLoading: isLoadingWhitelistedDomains,
  } = useGetWhitelistedDomainsQuery(undefined, { skip: !isMultibrandEnabled });

  if (!isMultibrandEnabled) {
    return [];
  }

  return !isLoadingWhitelistedDomains &&
    !whitelistedDomainsData?.whitelist?.length
    ? [ALLOW_LIST_EMPTY]
    : [];
};

const SolveConfigHeader = ({
  activeChannelTabIndex,
  channel,
  channelTabs,
  handleChannelTabChange,
}: {
  activeChannelTabIndex: ComponentProps<
    typeof import('@forethought-technologies/forethought-elements').Tabs
  >['value'];
  channel: Channel;
  channelTabs: string[];
  handleChannelTabChange: ComponentProps<
    typeof import('@forethought-technologies/forethought-elements').Tabs
  >['onChange'];
}) => {
  const isLoadingGlobalSettings = useIsGlobalMutationsSaving();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();

  const isSaving = useSelector(selectIsSaving) || isLoadingGlobalSettings;
  const isActive = useSelector(selectIsActive);
  const doesLiveVersionExistPlaybook = useSelector(
    selectLiveVersionExistsPlaybook,
  );
  const doesLiveVersionExistWidget = useSelector(selectLiveVersionExists);
  const doesLiveVersionExist =
    channel === 'playbook'
      ? doesLiveVersionExistPlaybook
      : doesLiveVersionExistWidget;
  const isValidationFailing = useSelector(selectIsValidationFailing);
  const isPlaybookValidationFailing = useSelector(
    selectIsPlaybookValidationFailing,
  );
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  const selectedPhoneNumber = useSelector(selectSelectedPhoneNumber);
  const selectedBrand = useGetSelectedBrand();
  const { refetch: refetchBrands } = useGetBrandsQuery();
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const [searchParams] = useSearchParams();

  const multibrandValidationErrors = useMultibrandValidationErrors();
  const canPublish =
    useSelector(state =>
      selectCanPublish(state as RootState, isMultibrandEnabled),
    ) && !multibrandValidationErrors.length;
  const canUpdatePublish =
    useSelector(state =>
      selectCanUpdatePublish(state as RootState, isMultibrandEnabled),
    ) && !multibrandValidationErrors.length;
  const isVoiceConfigModified = useSelector(selectIsVoiceConfigModified);
  const isPlaybookConfigModified = useSelector(selectIsPlaybookConfigModified);
  const isVoiceConfigPublishable = useSelector(selectCanPublishVoiceConfig);

  // Voice config - current selected phone number
  const params = new URLSearchParams(location.search);
  const number = params.get('number');

  const isPublishTab =
    !isMultibrandEnabled ||
    !['brand', 'embed'].includes(searchParams.get('tab') ?? '');

  const { publishedTimestamp, setPublishedTimestamp } = usePublishedTimestamp();
  const shouldShowPublishedMessage =
    publishedTimestamp !== -1 && !isSolveLiteEnabled && channel !== 'playbook';

  const productName = channel === 'playbook' ? 'Assist' : 'Widget';
  const channelName = channel === 'voice' ? 'Voice' : 'widget';

  const validationErrors = useSelector(state =>
    selectValidationErrors(state as RootState, isMultibrandEnabled),
  );

  const [confirmation, setConfirmation] = useState<
    'publish' | 'updatePublish' | 'activate' | 'deactivate' | ''
  >('');

  const handlePreview = () => {
    // if validation is failing, we show the warning modal and exit the function
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }
    dispatchTrackingAction(SolveConfigTrackingEventTypes.WIDGET_PREVIEW);
    navigate({
      pathname: Routes.SOLVE_PREVIEW,
      search: `?brandId=${selectedBrand?.brand_id}`,
    });
  };

  const handlePreviewVoice = () => {
    dispatchTrackingAction(SolveConfigTrackingEventTypes.VOICE_PREVIEW);
    const previewPath = generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
      channel: 'voice',
    });

    navigate({
      pathname: previewPath,
      search: number ? `number=${encodeURIComponent(number)}` : '',
    });
  };

  const handleConfirmationCancel = () => {
    setConfirmation('');
  };

  const shouldShowWarningModal =
    (channel === 'widget' && isValidationFailing) ||
    (channel === 'playbook' && isPlaybookValidationFailing);

  const handlePublishClick = () => {
    // if validation is failing, we show the warning modal and exit the function
    if (shouldShowWarningModal) {
      dispatch(showWarningModal({ isPlaybook: channel === 'playbook' }));
      return;
    }
    setConfirmation('publish');
  };

  const handleUpdatePublishClick = () => {
    // if validation is failing, we show the warning modal and exit the function
    if (shouldShowWarningModal) {
      dispatch(showWarningModal({ isPlaybook: channel === 'playbook' }));
      return;
    }
    setConfirmation('updatePublish');
  };

  const handlePublishConfirm = async ({
    isFirstTime,
  }: {
    isFirstTime: boolean;
  }) => {
    if (channel === 'widget') {
      const data = await dispatch(
        publishConfiguration({
          brandId: selectedBrand?.brand_id,
          isMultibrandEnabled,
        }),
      ).unwrap();

      if (isFirstTime && data.acknowledged) {
        /**
         * Should auto activate since this function happens on first publish
         * On fetch the widget config (on refresh) will have active as true
         */
        dispatch(setLiveVersionIsActive(true));
      }
      dispatchTrackingAction(
        SolveConfigTrackingEventTypes.CONFIGURATION_PUBLISHED,
      );
      setConfirmation('');
      setPublishedTimestamp(Date.now());

      // need to refetch the brands status
      refetchBrands();
    } else if (channel === 'voice') {
      await dispatch(
        publishVoiceConfiguration({
          phoneNumber: selectedPhoneNumber || '',
        }),
      ).unwrap();
      dispatchTrackingAction(
        SolveConfigTrackingEventTypes.VOICE_CONFIGURATION_PUBLISHED,
      );
      setConfirmation('');
    } else if (channel === 'playbook') {
      await dispatch(publishPlaybookConfiguration()).unwrap();
      dispatchTrackingAction(
        SolveConfigTrackingEventTypes.PLAYBOOK_CONFIGURATION_PUBLISHED,
      );
      setConfirmation('');
    }
  };

  const handleActivateConfirm = async () => {
    await dispatch(
      activateWidget({
        brandId: selectedBrand?.brand_id,
        isMultibrandEnabled,
      }),
    );
    dispatchTrackingAction(SolveConfigTrackingEventTypes.WIDGET_ACTIVATED);
    setConfirmation('');
    // need to refetch the brands status
    refetchBrands();
  };

  const handleDeactivateConfirm = async () => {
    await dispatch(
      deactivateWidget({
        brandId: selectedBrand?.brand_id,
        isMultibrandEnabled,
      }),
    );
    dispatchTrackingAction(SolveConfigTrackingEventTypes.WIDGET_DEACTIVATED);
    setConfirmation('');
    // need to refetch the brands status
    refetchBrands();
  };

  const solveLiteQuotas = paidPlanData?.product_quotas.filter(
    quota => quota.product === 'solve-widget',
  );
  const isTrialOrg = paidPlanData?.is_trial_org;

  const isSolveLiteTrialFinished =
    solveLiteQuotas && solveLiteQuotas.length > 0
      ? solveLiteQuotas[0].quota_status === 'exceeded' && isTrialOrg
      : false;

  const getA11yProps = useCallback(
    (index: number) => ({
      'aria-controls': `configuration-tabpanel-${channelTabs[
        index
      ].toLowerCase()}`,
      id: `configuration-tab-${channelTabs[index].toLowerCase()}`,
    }),
    [channelTabs],
  );

  const isPreviewDisabledForMultibrand =
    isMultibrandEnabled &&
    selectedBrand?.tags.length === 0 &&
    !selectedBrand.is_default;

  const previewDisableMessage = isPreviewDisabledForMultibrand
    ? 'To preview this brand, add a tag to the brand or make this the default brand'
    : '';

  const Message = () => {
    if (
      ((channel === 'widget' && canUpdatePublish) ||
        (channel === 'playbook' && isPlaybookConfigModified)) &&
      isPublishTab
    ) {
      return (
        <Typography
          color={theme.palette.colors.grey[500]}
          variant='font14Medium'
        >
          ‘Publish changes’ to make changes visible to others.
        </Typography>
      );
    }

    if (channel === 'playbook') {
      return null;
    }

    return (
      <MessageComponent
        messages={[
          ...validationErrors,
          ...(shouldShowPublishedMessage ? [PUBLISHED_RECENTLY] : []),
          ...multibrandValidationErrors,
        ]}
      />
    );
  };

  return (
    <Container>
      <Tabs
        a11yProps={getA11yProps}
        aria-label='Configuration channel'
        onChange={handleChannelTabChange}
        tabs={channelTabs}
        typographyVariant='font16Bold'
        value={activeChannelTabIndex}
      />

      {channel === 'widget' && (
        <Box alignItems='center' display='flex' gap='12px'>
          <Message />
          {doesLiveVersionExist && (
            <Tooltip
              tooltipContent={
                isActive
                  ? 'Available to the public'
                  : 'Unavailable to the public'
              }
            >
              <Box
                sx={{
                  '& .MuiSwitch-root': {
                    overflow: 'visible',
                  },
                }}
              >
                <Toggle
                  checked={isActive && !isSolveLiteTrialFinished}
                  disabled={isSolveLiteTrialFinished}
                  onChange={() => {
                    isActive
                      ? setConfirmation('deactivate')
                      : setConfirmation('activate');
                  }}
                />
              </Box>
            </Tooltip>
          )}
          <SavingAndButtonsContainer>
            {isSaving && (
              <Saving aria-busy>
                Saving
                <LoadingIndicator
                  color={theme.palette.colors.purple[500]}
                  size='large'
                />
              </Saving>
            )}
            <ButtonsContainer isHidden={isSaving}>
              <Tooltip tooltipContent={previewDisableMessage}>
                <Button
                  disabled={isSaving || isPreviewDisabledForMultibrand}
                  onClick={handlePreview}
                  variant='secondary'
                >
                  Preview
                </Button>
              </Tooltip>

              {isPublishTab && (
                <>
                  {doesLiveVersionExist ? (
                    <Button
                      disabled={!canUpdatePublish}
                      onClick={handleUpdatePublishClick}
                      variant='main'
                    >
                      Publish changes
                    </Button>
                  ) : (
                    <Button
                      disabled={!canPublish}
                      onClick={handlePublishClick}
                      variant='main'
                    >
                      Publish
                    </Button>
                  )}
                </>
              )}
            </ButtonsContainer>
          </SavingAndButtonsContainer>
        </Box>
      )}
      {channel === 'playbook' && (
        <Box alignItems='center' display='flex' gap='12px'>
          <Message />
          <SavingAndButtonsContainer>
            {isSaving && (
              <Saving aria-busy>
                Saving
                <LoadingIndicator
                  color={theme.palette.colors.purple[500]}
                  size='large'
                />
              </Saving>
            )}
            <ButtonsContainer isHidden={isSaving}>
              {isPublishTab && (
                <>
                  {doesLiveVersionExist ? (
                    <Button
                      disabled={!isPlaybookConfigModified}
                      onClick={handleUpdatePublishClick}
                      variant='main'
                    >
                      Publish changes
                    </Button>
                  ) : (
                    <Button onClick={handlePublishClick} variant='main'>
                      Publish
                    </Button>
                  )}
                </>
              )}
            </ButtonsContainer>
          </SavingAndButtonsContainer>
        </Box>
      )}

      {channel === 'voice' && (
        <Box alignItems='center' display='flex' gap='12px'>
          <Button
            disabled={isSaving}
            onClick={handlePreviewVoice}
            variant='secondary'
          >
            Preview
          </Button>

          <SavingAndButtonsContainer>
            {isSaving && (
              <Saving aria-busy>
                Saving
                <LoadingIndicator
                  color={theme.palette.colors.purple[500]}
                  size='large'
                />
              </Saving>
            )}
            <ButtonsContainer isHidden={isSaving}>
              <Tooltip
                tooltipContent={
                  !isVoiceConfigPublishable &&
                  'Failed validation. Please fix before publish'
                }
              >
                {doesLiveVersionExist ? (
                  <Button
                    disabled={
                      !isVoiceConfigModified || !isVoiceConfigPublishable
                    }
                    onClick={handleUpdatePublishClick}
                    variant='main'
                  >
                    Publish changes
                  </Button>
                ) : (
                  <Button
                    disabled={!isVoiceConfigPublishable}
                    onClick={handlePublishClick}
                    variant='main'
                  >
                    Publish
                  </Button>
                )}
              </Tooltip>
            </ButtonsContainer>
          </SavingAndButtonsContainer>
        </Box>
      )}
      {channel === 'api' && (
        <Box alignItems='center' display='flex' gap='12px'>
          <Button
            onClick={() => {
              navigate({
                pathname: generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
                  channel: 'api',
                }),
              });
            }}
            variant='secondary'
          >
            Preview
          </Button>
        </Box>
      )}

      <ConfirmModal
        actions={[
          {
            onClick: handleConfirmationCancel,
            title: 'Cancel',
          },
          {
            onClick: () => handlePublishConfirm({ isFirstTime: true }),
            title: 'Publish now',
          },
        ]}
        description={`You are about to make the ${productName} ${channelName} available to the public. ${
          channel === 'widget'
            ? 'This action will publish and display the widget on your website'
            : ''
        }.`}
        isOpen={confirmation === 'publish'}
        onClose={handleConfirmationCancel}
        title={`Publish ${productName}?`}
      />

      <ConfirmModal
        actions={[
          {
            onClick: handleConfirmationCancel,
            title: 'Cancel',
          },
          {
            onClick: async () => {
              await handlePublishConfirm({ isFirstTime: false });
              dispatch(
                setGlobalToastOptions({
                  autoHideDuration: 3000,
                  subtitle:
                    channel === 'playbook'
                      ? undefined
                      : 'Live config will be updated within 5 minutes',
                  title: `${capitalize(channel)} Configuration published`,
                  variant: 'main',
                }),
              );
            },
            title: 'Publish changes now',
          },
        ]}
        description={`You are about to update the published ${productName} configuration, and all changes will be made public.`}
        isOpen={confirmation === 'updatePublish'}
        onClose={handleConfirmationCancel}
        title='Publish changes?'
      />

      <ConfirmModal
        actions={[
          {
            onClick: handleConfirmationCancel,
            title: 'Cancel',
          },
          {
            onClick: handleActivateConfirm,
            title: 'Turn on',
          },
        ]}
        description={`You are about to make the ${productName} widget available to the public. \
          This action will display the widget on your website.`}
        isOpen={confirmation === 'activate'}
        onClose={handleConfirmationCancel}
        title={`On: ${productName} activation`}
      />

      <ConfirmModal
        actions={[
          {
            onClick: handleConfirmationCancel,
            title: 'Cancel',
          },
          {
            onClick: handleDeactivateConfirm,
            title: 'Turn off',
          },
        ]}
        description={`You are about to make the ${productName} widget unavailable to the public. \
          This action will remove the widget from your website. You can always make it available again in the future.`}
        isOpen={confirmation === 'deactivate'}
        onClose={handleConfirmationCancel}
        title={`Off: ${productName} deactivation`}
      />
    </Container>
  );
};

export default SolveConfigHeader;

export const Container = styled('div')`
  display: flex;
  padding: 0 25px 0 34px;
  min-height: 48px;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 2px;
  background: ${Colors.ui.backgrounds.default};
`;

const SavingAndButtonsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonsContainer = styled('div')<{ isHidden: boolean }>`
  visibility: ${props => props.isHidden && 'hidden'};
  display: flex;
  gap: 12px;
`;

const Saving = styled('div')`
  position: absolute;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.palette.colors.purple[500]};
  display: flex;
  align-items: center;
`;
