import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useRTVIClient } from '@pipecat-ai/client-react';

import { PreviewChatBot } from './PreviewChatBot';
import { getBoughtPhoneNumbers } from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';

export const VoicePreviewSection = () => {
  const dispatch = useAppDispatch();
  const client = useRTVIClient();

  const disconnectCall = async () => {
    await client?.disconnect();
  };

  useEffect(() => {
    dispatch(getBoughtPhoneNumbers());
  }, [dispatch]);

  useEffect(() => {
    disconnectCall();

    return () => {
      disconnectCall();
    };
    // only trigger this once upon component mount/unmount. We want to make sure the preview
    // is on a clean state when it is landed upon.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      alignItems='center'
      alignSelf='center'
      display='flex'
      flex={1}
      flexDirection='column'
      height='100%'
      justifyContent='center'
    >
      <PreviewChatBot />
    </Box>
  );
};
