import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { TTS_SPEED } from './constants';
import { solveVoiceTTSBytes } from 'src/services/solve-config/solveConfigApi';
import { selectBoughtNumbers } from 'src/slices/solve-config/solveConfigSlice';
import { selectPreviewPhoneNumber } from 'src/slices/workflow-preview/workflowPreviewSlice';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
};

export const useTTSAudio = () => {
  const [isBuffering, setIsBuffering] = useState(false);

  const handlePlay = async ({
    language,
    onFinishPlaying,
    speed,
    text,
    voiceId,
  }: {
    language: string;
    onFinishPlaying: () => void;
    speed: TTS_SPEED;
    text?: string;
    voiceId: string;
  }) => {
    setIsBuffering(true);

    const response = await solveVoiceTTSBytes({
      language,
      speed,
      transcript:
        text || "Hi I'm your customer support. How can I help you today?",
      voice_id: voiceId,
    });

    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const audio = new Audio(blobUrl);
    audio.addEventListener('ended', function () {
      onFinishPlaying();
    });

    setIsBuffering(false);
    audio.play();
  };

  return { handlePlay, isBuffering };
};

export const useGetBoughtNumberOptions = () => {
  const boughtNumbers = useSelector(selectBoughtNumbers);

  const boughtNumberOptions = useMemo(() => {
    if (!boughtNumbers) {
      return [];
    }
    return boughtNumbers.map(numberConfig => ({
      label: `${formatPhoneNumber(
        numberConfig.phone_number,
      )} (${capitalizeFirstLetter(numberConfig.language)})`,
      value: numberConfig.phone_number,
    }));
  }, [boughtNumbers]);

  return boughtNumberOptions;
};

export const useGetPreviewNumberLanguage = () => {
  const previewPhoneNumber = useSelector(selectPreviewPhoneNumber);
  const boughtNumbers = useSelector(selectBoughtNumbers);

  const previewLanguage = useMemo(() => {
    const numberConfig = boughtNumbers.find(
      numberConfig => numberConfig.phone_number === previewPhoneNumber,
    );

    if (!numberConfig) {
      return 'english';
    }

    return numberConfig.language;
  }, [boughtNumbers, previewPhoneNumber]);

  return previewLanguage;
};
