import 'react-phone-input-2/lib/style.css';

import { useMemo, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { Box, Slider, styled, useTheme } from '@mui/material';

import {
  LoadingIndicator,
  SearchBar,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useDebouncedSubmitVoiceConfigForNonRTE } from '../../hooks/useDebouncedSubmitConfig';
import { TTS_SPEED, VOICE_LANGUAGE_OPTIONS } from '../constants';
import { AgentVoice } from './AgentVoice';
import { VoiceConfigSection } from './VoiceConfigSection';
import {
  getAvailableVoices,
  selectAvailableVoices,
  selectIsSwitchingConfig,
  selectSelectedPhoneNumber,
  selectVoiceConfiguration,
  setVoiceDataByKey,
} from 'src/slices/solve-config/solveConfigSlice';
import { VoiceConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';
import { isPhoneNumberValid } from 'src/utils/validators';

export const VoiceConfig = () => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const selectedNumber = useSelector(selectSelectedPhoneNumber);
  const isSwitchingConfig = useSelector(selectIsSwitchingConfig);
  const voiceConfiguration = useSelector(selectVoiceConfiguration);
  const availableVoices = useSelector(selectAvailableVoices);
  const [voiceSearchText, setVoiceSearchText] = useState('');
  const [isEditingDefaultHandoffNumber, setIsEditingDefaultHandoffNumber] =
    useState(false);

  const {
    agent_name: agentName,
    company_name: companyName,
    default_handoff_number: defaultHandoffNumber,
    default_language: defaultLanguage,
    greeting_message: greetingMessage,
    tts_speed: ttsSpeed,
  } = voiceConfiguration;

  const debouncedSubmitConfig = useDebouncedSubmitVoiceConfigForNonRTE();

  const filteredVoices = useMemo(() => {
    return availableVoices.filter(voice =>
      voice.name.toLowerCase().includes(voiceSearchText.trim().toLowerCase()),
    );
  }, [availableVoices, voiceSearchText]);

  const updateAgentVoiceId = (voiceId: string) => {
    dispatch(
      setVoiceDataByKey({
        key: 'agent_voice_id',
        value: voiceId,
      }),
    );

    const updatedConfig: VoiceConfiguration = {
      ...voiceConfiguration,
      agent_voice_id: voiceId,
    };
    debouncedSubmitConfig(updatedConfig, 'agent_voice_id', voiceId);
  };

  const updateDefaultHandoffNumber = (value: string) => {
    dispatch(
      setVoiceDataByKey({
        key: 'default_handoff_number',
        value: value,
      }),
    );

    const updatedConfig: VoiceConfiguration = {
      ...voiceConfiguration,
      default_handoff_number: value,
    };
    debouncedSubmitConfig(updatedConfig, 'default_handoff_number', value);
  };

  const isDefaultHandoffNumberValid = useMemo(() => {
    return !defaultHandoffNumber || isPhoneNumberValid(defaultHandoffNumber);
  }, [defaultHandoffNumber]);

  if (isSwitchingConfig) {
    return (
      <Box mt='30%'>
        <LoadingIndicator color={palette.colors.purple[500]} size='large' />
      </Box>
    );
  }

  if (!selectedNumber) {
    return (
      <Box mt='30%' textAlign='center'>
        <Typography color={palette.colors.grey[600]} variant='font20'>
          Create and manage phone numbers on the Phone tab
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <VoiceConfigSection
        title='Select default language'
        tooltip='The default language is set for the phone number. Please ensure the
              agent name, company name, and greeting message are using the same language as the default language.'
      >
        <SelectDropdown
          id='select default language'
          onChange={e => {
            dispatch(
              setVoiceDataByKey({
                key: 'default_language',
                value: e.target.value,
              }),
            );

            dispatch(getAvailableVoices({ language: e.target.value }));

            const updatedConfig: VoiceConfiguration = {
              ...voiceConfiguration,
              default_language: e.target.value,
            };
            debouncedSubmitConfig(
              updatedConfig,
              'default_language',
              e.target.value,
            );
          }}
          options={VOICE_LANGUAGE_OPTIONS}
          placeholder='Select default language'
          value={defaultLanguage}
        />
      </VoiceConfigSection>

      <VoiceConfigSection
        title='Company name'
        tooltip='This helps the AI agent recognize the business context for responses.'
      >
        <TextField
          aria-label='Update company name'
          multiline
          onChange={e => {
            dispatch(
              setVoiceDataByKey({
                key: 'company_name',
                value: e.target.value,
              }),
            );

            const updatedConfig: VoiceConfiguration = {
              ...voiceConfiguration,
              company_name: e.target.value,
            };
            debouncedSubmitConfig(
              updatedConfig,
              'company_name',
              e.target.value,
            );
          }}
          placeholder='Company name'
          value={companyName || ''}
        />
      </VoiceConfigSection>

      <VoiceConfigSection
        title='AI agent name'
        tooltip='This helps the AI agent recognize the business context for responses.'
      >
        <TextField
          aria-label='Update agent name'
          multiline
          onChange={e => {
            dispatch(
              setVoiceDataByKey({
                key: 'agent_name',
                value: e.target.value,
              }),
            );

            const updatedConfig: VoiceConfiguration = {
              ...voiceConfiguration,
              agent_name: e.target.value,
            };
            debouncedSubmitConfig(updatedConfig, 'agent_name', e.target.value);
          }}
          placeholder='Agent name'
          value={agentName || ''}
        />
      </VoiceConfigSection>

      <VoiceConfigSection title='Greeting'>
        <TextField
          aria-label='Update greeting message'
          multiline
          onChange={e => {
            dispatch(
              setVoiceDataByKey({
                key: 'greeting_message',
                value: e.target.value,
              }),
            );

            const updatedConfig: VoiceConfiguration = {
              ...voiceConfiguration,
              greeting_message: e.target.value,
            };
            debouncedSubmitConfig(
              updatedConfig,
              'greeting_message',
              e.target.value,
            );
          }}
          placeholder='Greeting message'
          rows={3}
          value={greetingMessage}
        />
      </VoiceConfigSection>

      <VoiceConfigSection
        title='Default handoff number'
        tooltip='This phone will be used in case of unexpected errors or interference.'
      >
        <Box display='flex' flexDirection='column' gap='4px'>
          <PhoneInput
            country='us'
            enableSearch
            inputProps={{
              // 'aria-errormessage': validationMessageId,
              'aria-invalid': isDefaultHandoffNumberValid,
              'aria-label': 'enter default handoff number',
            }}
            inputStyle={{
              border: `1px solid ${
                isEditingDefaultHandoffNumber
                  ? palette.colors.purple[500]
                  : isDefaultHandoffNumberValid
                  ? palette.colors.grey[500]
                  : palette.colors.red[500]
              }`,
              borderRadius: '4px',
              color: palette.text.primary,
              fontSize: '14px',
              height: '40px',
              marginLeft: '40px',
              padding: '10px 12px',
              width: '-webkit-fill-available',
            }}
            onBlur={() => setIsEditingDefaultHandoffNumber(false)}
            onChange={updateDefaultHandoffNumber}
            onFocus={() => setIsEditingDefaultHandoffNumber(true)}
            specialLabel=''
            value={defaultHandoffNumber || ''}
          />
          {!isDefaultHandoffNumberValid && (
            <Typography color={palette.error.main} variant='font12'>
              Invalid phone number. Leave this field empty or enter a valid
              phone number.
            </Typography>
          )}
        </Box>
      </VoiceConfigSection>

      <VoiceConfigSection title='AI Voice Speed'>
        <Box alignSelf='center' width='100%'>
          <Slider
            aria-label='AI voice speed'
            defaultValue={TTS_SPEED.NORMAL}
            marks
            max={5}
            min={1}
            onChange={(_, value) => {
              if (typeof value !== 'number') {
                return;
              }
              dispatch(
                setVoiceDataByKey({
                  key: 'tts_speed',
                  value: value,
                }),
              );

              const updatedConfig: VoiceConfiguration = {
                ...voiceConfiguration,
                tts_speed: value,
              };
              debouncedSubmitConfig(
                updatedConfig,
                'tts_speed',
                value.toString(),
              );
            }}
            step={1}
            value={ttsSpeed}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='font14'>Slowest</Typography>
            <Typography variant='font14'>Normal</Typography>
            <Typography variant='font14'>Fastest</Typography>
          </Box>
        </Box>
      </VoiceConfigSection>

      <Section>
        <Typography variant='font16Bold'>Choose your AI voice</Typography>
        <Typography variant='font14'>
          Turn on the speakers and test the voice.
        </Typography>
        <SearchBar
          aria-label='Voice search bar'
          onChange={e => setVoiceSearchText(e.target.value)}
          placeholder='Search'
          value={voiceSearchText}
        />
        <AgentVoiceSection>
          {filteredVoices.map(voice => (
            <AgentVoice
              key={voice.voice_id}
              updateAgentVoiceId={updateAgentVoiceId}
              voice={voice}
            />
          ))}
        </AgentVoiceSection>
      </Section>
    </Box>
  );
};

const AgentVoiceSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 520px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
